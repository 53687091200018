import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { When } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { PlaySquareIcon } from '@/atoms/Icons/PlaySquareIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { Image } from '@/atoms/Image'
import { LabelMD, ParagraphSM, TitleMD } from '@/atoms/Text'
import { paths } from '@/constants'
import { Modal } from '@/molecules/Modal'
import { LightFranchise } from '@/services/FranchiseService'
import { inTheaters } from '@/services/PhaseManagerService'
import { Project } from '@/services/ProjectsService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useSafeTrack } from '@/utils/analytics'
import { FRANCHISE_CTA_MODAL, getObjectFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { isDefined } from '@/utils/types'

interface FranchiseFilmSeriesCTAProps {
  project: Project | undefined
  franchise?: LightFranchise
}

interface SeenModal {
  tickets?: boolean
  watch?: boolean
}

export const FranchiseFilmSeriesCTA: FC<FranchiseFilmSeriesCTAProps> = React.memo(({ project, franchise }) => {
  const { t } = useTranslate('watch')
  const track = useSafeTrack()
  const [showModal, setShowModal] = useState(false)
  const franchiseMovieProject = franchise?.projects && franchise.projects.find((p) => p?.projectType === 'movie')
  const franchiseMovieProjectPhases = franchiseMovieProject?.primaryFlowPhases?.filter(isDefined) ?? []
  const showTickets = inTheaters(franchiseMovieProjectPhases)

  const seenModal: SeenModal = useMemo(() => {
    return getObjectFromLocalStorage(FRANCHISE_CTA_MODAL) || {}
  }, [])

  const handleModalClose = useCallback(() => {
    setShowModal(false)

    const updated = showTickets ? { ...seenModal, tickets: true } : { ...seenModal, watch: true }

    writeToLocalStorage(FRANCHISE_CTA_MODAL, updated)
  }, [showTickets, seenModal])

  const handleClick = useCallback(
    (variant: string) => {
      switch (variant) {
        case 'getShowtimes':
          track('Clicked Franchise Film CTA Get Showtimes', {
            project,
          })
          break
        case 'watchSeries':
          track('Clicked Franchise Film CTA Watch Series', {
            project,
          })
          break
        case 'joinGuild':
          track('Clicked Franchise Film CTA Join Guild', {
            project,
          })
          break
      }
      handleModalClose()
    },
    [track, project, handleModalClose],
  )

  useEffect(() => {
    if ((!seenModal?.tickets && showTickets) || (!seenModal?.watch && !showTickets)) {
      setShowModal(true)
    }
  }, [seenModal, showTickets])

  const movieTitle = project?.name
  // hardcoding image urls for now to be for movie and not series
  const portraitImageUrl = 'v1730220132/studio-app/catalog/b47bb274-6aa4-4bf8-b731-28b9efea4a45'
  const landscapeImageUrl = 'v1730220298/studio-app/catalog/4297c379-a0bc-47b3-ac0e-8420ec876963'
  const ticketsLink = franchiseMovieProject?.slug
    ? `${paths.tickets.index}/${franchiseMovieProject.slug}`
    : paths.tickets.index
  const watchLink = franchiseMovieProject?.slug
    ? `${paths.watch.index}/${franchiseMovieProject.slug}`
    : paths.watch.index

  return (
    <Modal
      panelClasses="!p-4 bg-white text-black !max-w-[430px] lg:!max-w-[735px]"
      isOpen={showModal}
      onClose={handleModalClose}
    >
      <div className="absolute right-3 top-3 cursor-pointer" onClick={handleModalClose}>
        <CloseIcon size={20} color="core-gray-700" />
      </div>
      <div className="mt-4 flex flex-col gap-4 lg:flex-row lg:gap-8 lg:px-4 lg:pb-4">
        <div className="w-full shrink-0 lg:w-fit">
          <Image
            src={getCloudinaryImageUrl({
              path: portraitImageUrl + '.webp',
              width: 224,
              height: 336,
              transforms: 'c_fill',
            })}
            alt={`${movieTitle} poster`}
            width={224}
            height={336}
            className="hidden rounded-xl lg:flex"
          />
          <Image
            src={getCloudinaryImageUrl({
              path: landscapeImageUrl + '.webp',
              width: 396,
              height: 222,
              transforms: 'c_fill',
            })}
            alt={`${movieTitle} poster`}
            width={396}
            height={222}
            className="flex max-w-full rounded-xl lg:hidden"
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <TitleMD weight="bold" className="lg:photon-heading-xs max-w-[415px]">
            <Translate i18nKey="haveYouSeenTheFilm" t={t}>
              Have you seen the film?
            </Translate>
          </TitleMD>
          <ParagraphSM>
            <Translate i18nKey="weRecommendWatchingTheFilmBeforeSeries" t={t}>
              We recommend watching the film before the Series.
            </Translate>
          </ParagraphSM>

          <div className="flex flex-col gap-4 lg:mt-4 lg:flex-row">
            <When condition={showTickets}>
              <LinkButton
                onClick={() => handleClick('getShowtimes')}
                variant="oxide-bright"
                href={ticketsLink}
                className="w-full border-core-gray-300 bg-core-gray-300 px-[16px] py-[13px] text-core-gray-950 ring-0 ring-transparent lg:w-fit"
              >
                <div className="flex min-h-6 flex-row items-center">
                  <TicketIcon color="black" size={20} />
                  <LabelMD className="pl-1">{t('getShowtimes', 'Get Showtimes')}</LabelMD>
                </div>
              </LinkButton>
            </When>
            <When condition={!showTickets}>
              <LinkButton
                onClick={() => handleClick('watchFilm')}
                variant="oxide-bright"
                href={watchLink}
                className="w-full border-core-gray-300 bg-core-gray-300 px-[16px] py-[13px] text-core-gray-950 ring-0 ring-transparent lg:w-fit"
              >
                <div className="flex min-h-6 flex-row items-center">
                  <PlaySquareIcon color="black" size={20} />
                  <LabelMD className="pl-1">{t('watchTheFilm', 'Watch the Film')}</LabelMD>
                </div>
              </LinkButton>
            </When>
            <Button
              onClick={() => handleClick('watchSeries')}
              variant="oxide-bright"
              className="relative w-full overflow-hidden border-core-gray-300 bg-core-gray-300 px-[16px] py-[13px] text-core-gray-950 ring-0 ring-transparent lg:w-fit"
            >
              <div className="relative flex min-h-6 flex-row items-center">
                <LabelMD className="pl-1">{t('checkOutTheSeries', 'Check out the Series')}</LabelMD>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
})

FranchiseFilmSeriesCTA.displayName = 'React.memo(FranchiseFilmSeriesCTA)'
