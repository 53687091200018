import React, { useMemo, useRef } from 'react'
import { Maybe } from 'graphql/jsutils/Maybe'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { slugs } from '@/constants'
import { WatchDepartments } from '@/organisms/WatchDepartments'
import { BackgroundPosition, WatchProjectHero, WatchProjectHeroNew } from '@/organisms/WatchProjectHero'
import { CatalogTitle } from '@/services/ContentCatalog'
import { Downloadable } from '@/services/DownloadablesService'
import { ShopifyStoreConfig } from '@/services/EarlyAccessService'
import { LightFranchise } from '@/services/FranchiseService'
import { useGuildUser } from '@/services/GuildUserService'
import { isSellingTickets } from '@/services/PhaseManagerService'
import { Project, ProjectType } from '@/services/ProjectsService'
import { SeasonLinkMeta } from '@/services/ProjectsService/types'
import { PageDataContext, PageModel } from '@/services/RenderService'
import { Story } from '@/services/StoriesService'
import { GetTheatricalPreReleasePageDataResult } from '@/services/TheatricalPreReleaseService'
import { useUser } from '@/services/UserService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import {
  getContinueWatchingCta,
  getContinueWatchingCtaNew,
  getDefaultCta,
  getTrailerCta,
  isARecap,
  isASneakPeek,
} from '@/utils/EpisodeUtil'
import { useLocale } from '@/utils/LocaleUtil'
import { getProjectTheme } from '@/utils/project-themes'
import { useTranslate } from '@/utils/translate/translate-client'
import { AppPromoSection } from '@/views/LandingView/AppPromoSection'
import { useWatchProjectData } from '@/views/WatchProjectView/useWatchProjectData'
import { FranchiseFilmSeriesCTA } from './FranchiseFilmSeriesCTA'
import { WatchProjectContext } from './WatchProjectContext'
import { useFadeBackgroundOnScroll } from './useFadeBackgroundOnScroll'

export interface WatchProjectViewProps {
  currentlyFunding?: boolean
  discoveryPosterLandscapeCloudinaryPath: string
  downloadables: Downloadable[]
  hasPayItForward: boolean
  id: number
  name: string
  projectType: ProjectType
  shopifyConfig?: ShopifyStoreConfig
  singleStory?: boolean
  slug: string
  story?: Story
  theaterBackgroundPosition?: BackgroundPosition
  theaterDescription: string
  theatricalRegion?: TheatricalRegion[]
  theatricalRelease?: TheatricalReleaseObject
  seasons?: SeasonLinkMeta[]
  pageDataContext: Maybe<PageDataContext>
  page: Maybe<PageModel>
  contentfulProjectTheme: Maybe<ProjectTheme>
  catalogTitle?: CatalogTitle
  pageData: GetTheatricalPreReleasePageDataResult
  isProjectInEarlyAccess?: boolean
  projectData: Project
  guildScore?: number
  isGuildMember?: boolean
  isLoggedIn?: boolean
  franchise?: LightFranchise
  hasWatchableReasons?: boolean
}

export const WatchProjectView: React.FC<WatchProjectViewProps> = (props) => {
  const {
    discoveryPosterLandscapeCloudinaryPath,
    downloadables,
    hasPayItForward,
    id,
    name,
    projectType,
    singleStory,
    story,
    slug,
    theaterBackgroundPosition,
    theaterDescription,
    theatricalRegion,
    theatricalRelease,
    seasons = [],
    page,
    pageData,
    pageDataContext,
    contentfulProjectTheme,
    catalogTitle,
    isProjectInEarlyAccess = false,
    projectData,
    shopifyConfig,
    guildScore,
    franchise,
    hasWatchableReasons = false,
  } = props
  const backgroundRef = useRef<HTMLDivElement>(null)
  const boundaryRef = useRef<HTMLDivElement>(null)
  useFadeBackgroundOnScroll({ backgroundRef, boundaryRef })
  const { isGuildMember: isGuildMemberFromProps } = props
  const { isGuildMember: isGuildMemberFromHook } = useGuildUser()
  const { isLoggedIn: isLoggedInFromProps } = props
  const { isLoggedIn: isLoggedInFromHook } = useUser()

  // Some Watch Project Pages are statically generated, so we need to check if the user is a guild member here when not provided server-side
  const isGuildMember = isGuildMemberFromProps !== undefined ? isGuildMemberFromProps : isGuildMemberFromHook
  const isLoggedIn = isLoggedInFromProps !== undefined ? isLoggedInFromProps : isLoggedInFromHook
  const canShowTickets = isSellingTickets(projectData?.primaryFlowPhases ?? [])
  const canShowGuildHero = Boolean(
    !canShowTickets && isProjectInEarlyAccess && (!isLoggedIn || (isLoggedIn && !isGuildMember)),
  )

  const { t } = useTranslate('watch')
  const { locale } = useLocale()
  const { currentEpisode } = useWatchProjectData({ data: projectData, skip: !seasons?.length, isGuildMember })
  const projectTheme = getProjectTheme(slug) ?? {}
  const episodeTrailer = seasons[0]?.episodes?.find((episode) => episode.isTrailer)
  const showFranchiseFilmSeriesCTA = Boolean(projectData?.slug === slugs.homesteadSeries)

  const showHeroProps = useMemo(() => {
    if (projectData?.continueWatching?.guid && currentEpisode) {
      const resume = t('resume', 'Resume')
      return getContinueWatchingCta({ currentEpisode, locale, projectType, resume, slug })
    } else if (isARecap(currentEpisode)) {
      const ctaText = t('watchRecap', 'Watch Recap')
      return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
    } else if (isASneakPeek(currentEpisode)) {
      const ctaText = t('watchSneakPeek', 'Watch Sneak Peek')
      return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
    } else if (currentEpisode?.isTrailer) {
      const episodeName = currentEpisode?.name ?? 'Trailer'
      const ctaText = episodeName.toLowerCase().includes('trailer')
        ? t('watchTrailer', 'Watch Trailer')
        : t('watchEpisodeName', 'Watch {{ episodeName }}', {
            episodeName,
          })
      return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
    } else if (!isGuildMember && isProjectInEarlyAccess && projectData?.trailers && projectData?.trailers?.length > 0) {
      return {
        showDescription: theaterDescription,
        ctaPath: `/watch/${slug}/videos/trailers/${projectData?.trailers?.[0].id}`,
        ctaText: t('watchTrailer', 'Watch Trailer'),
      }
    } else {
      const ctaText = t('watchNow', 'Watch Now')
      return getDefaultCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
    }
  }, [
    projectData?.continueWatching?.guid,
    projectData?.trailers,
    currentEpisode,
    isGuildMember,
    isProjectInEarlyAccess,
    t,
    locale,
    projectType,
    slug,
    theaterDescription,
  ])

  const showHeroNewProps = useMemo(() => {
    if (projectData?.continueWatching?.guid && currentEpisode) {
      const resume = t('continue', 'Continue')
      return getContinueWatchingCtaNew({ currentEpisode, locale, projectType, resume, slug })
    } else if (isASneakPeek(currentEpisode)) {
      const ctaText = contentfulProjectTheme?.sneakPeekDuration
        ? t('watchFirstMinutes', `Watch The First {{ minutes }} Minutes`, {
            minutes: contentfulProjectTheme.sneakPeekDuration,
          })
        : t('watchSneakPeek', 'Watch Sneak Peek')
      return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
    } else if (currentEpisode?.isTrailer) {
      const episodeName = currentEpisode?.name ?? 'Trailer'
      const ctaText = episodeName.toLowerCase().includes('trailer')
        ? t('watchTrailer', 'Watch Trailer')
        : t('watchEpisodeName', 'Watch {{ episodeName }}', {
            episodeName,
          })
      return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
    } else if (projectData?.trailers && projectData?.trailers?.length > 0) {
      return {
        showDescription: theaterDescription,
        ctaPath: `/watch/${slug}/videos/trailers/${projectData?.trailers?.[0].id}`,
        ctaText: t('watchTrailer', 'Watch Trailer'),
      }
    } else if (!isGuildMember && isProjectInEarlyAccess && episodeTrailer) {
      return {
        showDescription: theaterDescription,
        ctaPath: `/watch/${slug}/episode/${episodeTrailer.id}`,
        ctaText: t('watchTrailer', 'Watch Trailer'),
      }
    } else {
      const ctaText = t('watchNow', 'Watch Now')
      return getDefaultCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
    }
  }, [
    contentfulProjectTheme?.sneakPeekDuration,
    projectData?.continueWatching?.guid,
    projectData?.trailers,
    currentEpisode,
    isGuildMember,
    isProjectInEarlyAccess,
    episodeTrailer,
    t,
    locale,
    projectType,
    slug,
    theaterDescription,
  ])

  return (
    <WatchProjectContext.Provider value={{ projectTheme, project: projectData }}>
      <div className="overflow-x-hidden bg-core-gray-950 pt-24 text-white">
        <main>
          <When condition={canShowGuildHero}>
            <PaddedContainer fluid className="min-h-[427px] md:min-h-[429px]">
              <WatchProjectHeroNew
                className="mb-12"
                cloudinaryImgPath={
                  discoveryPosterLandscapeCloudinaryPath
                    ? `${discoveryPosterLandscapeCloudinaryPath}.webp`
                    : '/v1706130971/angel-app/angel-studios/discovery_images/Theater-Home-Page-Banner_v2_2024-01-16_1920x1080.webp'
                }
                currentEpisode={currentEpisode}
                hasPayItForward={hasPayItForward}
                id={id}
                promotion={null}
                showTitle={name}
                slug={slug}
                theaterBackgroundPosition={theaterBackgroundPosition}
                theatricalRegion={theatricalRegion}
                theatricalRelease={theatricalRelease}
                isGuildMemberContent={isProjectInEarlyAccess}
                catalogTitle={catalogTitle}
                projectType={projectType}
                pageData={pageData}
                shopifyConfig={shopifyConfig}
                contentfulProjectTheme={contentfulProjectTheme}
                guildScore={guildScore}
                isGuildMember={isGuildMember}
                {...showHeroNewProps}
              />
            </PaddedContainer>
          </When>
          <When condition={!canShowGuildHero}>
            <PaddedContainer fluid>
              <WatchProjectHero
                className="mb-12"
                cloudinaryImgPath={
                  discoveryPosterLandscapeCloudinaryPath
                    ? `${discoveryPosterLandscapeCloudinaryPath}.webp`
                    : '/v1706130971/angel-app/angel-studios/discovery_images/Theater-Home-Page-Banner_v2_2024-01-16_1920x1080.webp'
                }
                currentEpisode={currentEpisode}
                id={id}
                promotion={null}
                showTitle={name}
                slug={slug}
                theaterBackgroundPosition={theaterBackgroundPosition}
                theatricalRegion={theatricalRegion}
                theatricalRelease={theatricalRelease}
                isGuildMemberContent={isProjectInEarlyAccess}
                projectType={projectType}
                pageData={pageData}
                shopifyConfig={shopifyConfig}
                guildScore={guildScore}
                isGuildMember={isGuildMember}
                isLoggedIn={isLoggedIn}
                hasWatchableReasons={hasWatchableReasons}
                {...showHeroProps}
              />
            </PaddedContainer>
          </When>
        </main>
        <div ref={boundaryRef}>
          <WatchDepartments
            projectData={projectData}
            loadingProjectData={false}
            downloadables={downloadables}
            slug={slug}
            singleStory={singleStory}
            story={story}
            page={page}
            pageDataContext={pageDataContext}
            contentfulProjectTheme={contentfulProjectTheme}
            isGuildMember={isGuildMember}
            franchise={franchise}
            hasWatchableReasons={hasWatchableReasons}
          />
        </div>
        <LazyHydrate whenVisible>
          <AppPromoSection />
        </LazyHydrate>
        {showFranchiseFilmSeriesCTA && isGuildMember && (
          <FranchiseFilmSeriesCTA project={projectData} franchise={franchise} />
        )}
      </div>
    </WatchProjectContext.Provider>
  )
}
