import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { Else, If, Then } from 'react-if'
import { Button } from '@/atoms/Button'
import { InternalLink } from '@/atoms/InternalLink'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { LabelSM, ParagraphSM, TitleMD } from '@/atoms/Text'
import { UPDATED_LEGAL_DOCUMENTS } from '@/constants/cookies'
import { Durations } from '@/constants/durations'
import { Modal } from '@/molecules/Modal/Modal'
import { useBotDetection } from '@/services/BotDetection'
import { useAcceptLegalDocs, useUnacceptedLegalDocs } from '@/services/EllisIslandService/legalDocumentHooks'
import { useUser } from '@/services/UserService'
import { logger } from '@/utils/logging'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export const UPDATED_LEGAL_DOCS_MODAL_ID = 'updated-legal-docs-modal'

export const UpdatedLegalDocsModal: React.FC = () => {
  const { t } = useTranslate('common')
  const { isBot } = useBotDetection()
  const { asPath } = useRouter()
  const { isLoggedIn } = useUser()
  const [isOpen, setIsOpen] = useState(true)
  const [isVppaAccepted, setIsVppaAccepted] = useState(false)
  const { legalDocuments, response: getResponse, error: getError } = useUnacceptedLegalDocs()
  const { acceptLegalDocs, response: acceptResponse, error: acceptError, loading: acceptLoading } = useAcceptLegalDocs()

  const legalDocumentsToShow = useMemo(() => {
    const legalCookie = readLegalCookie()
    return legalDocuments.filter((doc) => !legalCookie || !legalCookie[doc.id])
  }, [legalDocuments])

  const termsOfUseLegalDoc = legalDocumentsToShow.find((d) => d.type === 'terms_of_use')
  const privacyPolicyLegalDoc = legalDocumentsToShow.find((d) => d.type === 'privacy_policy')
  const vppaLegalDoc = legalDocumentsToShow.find((d) => d.type === 'vppa')

  const handleVppaCheckboxChanged = useCallback(() => {
    setIsVppaAccepted((prev) => !prev)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)

    if (legalDocumentsToShow.length) {
      // Set the cookie here because:
      // 1. If the user tried to agree but an error occurred, we don't want to show the modal again for a day to ensure the user does not get spammed if Ellis is having issues.
      // 2. If the user did not agree, we don't want to show the modal again for a day so the user does not get spammed.
      const legalCookie = readLegalCookie()
      const additions = legalDocumentsToShow.reduce((obj: LegalCookie, doc) => {
        obj[doc.id] = 'viewed'
        return obj
      }, {})
      const updatedCookie: LegalCookie = { ...legalCookie, ...additions }
      writeLegalCookie(updatedCookie)
    }
  }, [legalDocumentsToShow])

  const handleAgreed = useCallback(() => {
    if (!legalDocumentsToShow) return

    const legalDocumentsToAccept = legalDocumentsToShow.filter((doc) => doc.required)
    if (vppaLegalDoc && isVppaAccepted) legalDocumentsToAccept.push(vppaLegalDoc)

    logger().info('User clicked to accept legal documents', { legalDocuments: legalDocumentsToAccept })
    acceptLegalDocs(legalDocumentsToAccept)
  }, [acceptLegalDocs, legalDocumentsToShow, isVppaAccepted, vppaLegalDoc])

  // Handle getLegalDocuments result
  useEffect(() => {
    const context = { legalDocuments, legalDocumentsToShow, getResponse }
    if (getError) {
      logger().error(
        'An error occurred when attempting to fetch legal documents that require acceptance.',
        context,
        getError,
      )
    } else if (getResponse && !getResponse.ok) {
      logger().error(
        'Received a failed response when attempting to get a list of legal documents that require acceptance.',
        context,
      )
    } else if (getResponse && getResponse.ok) {
      if (legalDocuments.length > 0) {
        if (legalDocumentsToShow.length > 0) {
          logger().info(
            'Found one or more legal documents that the user must accept. The modal will be shown.',
            context,
          )
        } else {
          logger().info(
            'Found legal documents the user needs to accept, but none will be shown right now because they have been viewed recently.',
            context,
          )
        }
      } else {
        logger().debug('There are no legal documents that this user needs to accept.')
      }
    }
  }, [getError, getResponse, legalDocuments, legalDocumentsToShow])

  // Handle acceptLegalDocuments result
  useEffect(() => {
    const context = { acceptResponse, legalDocuments: legalDocumentsToShow }
    if (acceptError) {
      logger().error('An error occurred when attempting to accept legal documents!', context, acceptError)
    } else if (acceptResponse && !acceptResponse.ok) {
      logger().error('Received a failed response when attempting to accept legal documents!', context)
    } else if (acceptResponse && acceptResponse.ok && legalDocumentsToShow.length > 0) {
      logger().info('User successfully accepted one or more updated legal documents.', {
        legalDocuments: legalDocumentsToShow,
      })
      handleClose()
    }
  }, [acceptError, acceptResponse, handleClose, legalDocumentsToShow])

  const showModal = legalDocumentsToShow.length > 0 && !isBot && isLoggedIn && !asPath.includes('/legal')
  if (!showModal) return null

  const agreeButtonLabel = t('agreeToLegalDocumentsAndContinue', 'Agree and Continue')

  return (
    <Modal id={UPDATED_LEGAL_DOCS_MODAL_ID} isOpen={isOpen} panelClasses="lg:min-w-[600px]">
      <div className="flex flex-col items-center gap-6 px-2 py-4 text-center md:px-8 md:py-12">
        <AngelLogo color="black" height={70} />
        <TitleMD weight="bold" className="md:photon-heading-xs lg:photon-heading-sm w-full">
          {t('updatedLegalDocumentModalTitle', {
            count: legalDocumentsToShow.length,
            defaultValue_one: 'Updated Legal Document',
            defaultValue_other: 'Updated Legal Documents',
          })}
        </TitleMD>
        <ParagraphSM className="text-pretty">
          {t('reviewUpdatedLegalDocumentsSubtitle', {
            count: legalDocumentsToShow.length,
            defaultValue_one: 'One of our legal documents has been updated.',
            defaultValue_other: 'Some of our legal documents have been updated.',
          })}
        </ParagraphSM>
        {termsOfUseLegalDoc && privacyPolicyLegalDoc ? (
          <ParagraphSM className="text-pretty">
            <Translate i18nKey="reviewUpdatedPrivacyPolicyAndTermsOfUse" t={t} values={{ agreeButtonLabel }}>
              By clicking the “{{ agreeButtonLabel }}” button below, you acknowledge that you have read and agree to our
              updated{' '}
              <InternalLink className="text-nowrap text-link-blue" href={termsOfUseLegalDoc.source} target="_blank">
                Terms of Use
              </InternalLink>{' '}
              and{' '}
              <InternalLink className="text-nowrap text-link-blue" href={privacyPolicyLegalDoc.source} target="_blank">
                Privacy Policy
              </InternalLink>
              .
            </Translate>
          </ParagraphSM>
        ) : termsOfUseLegalDoc ? (
          <ParagraphSM className="text-pretty">
            <Translate i18nKey="reviewUpdatedTermsOfUse" t={t} values={{ agreeButtonLabel }}>
              By clicking the “{{ agreeButtonLabel }}” button below, you acknowledge that you have read and agree to our
              updated{' '}
              <InternalLink className="text-nowrap text-link-blue" href={termsOfUseLegalDoc.source} target="_blank">
                Terms of Use
              </InternalLink>
              .
            </Translate>
          </ParagraphSM>
        ) : privacyPolicyLegalDoc ? (
          <ParagraphSM className="text-pretty">
            <Translate i18nKey="reviewUpdatedPrivacyPolicy" t={t} values={{ agreeButtonLabel }}>
              By clicking the “{{ agreeButtonLabel }}” button below, you acknowledge that you have read and agree to our
              updated{' '}
              <InternalLink className="text-nowrap text-link-blue" href={privacyPolicyLegalDoc.source} target="_blank">
                Privacy Policy
              </InternalLink>
              .
            </Translate>
          </ParagraphSM>
        ) : null}
        {vppaLegalDoc ? (
          <div className="flex flex-row items-center">
            <input
              id="vppa-checkbox"
              className="mr-2 h-4 w-4"
              type="checkbox"
              checked={isVppaAccepted}
              onChange={handleVppaCheckboxChanged}
            />
            <ParagraphSM className="text-pretty">
              <Translate i18nKey="readAndAgreeToVideoPrivacyPolicy" t={t}>
                I have read and agree to the{' '}
                <InternalLink className="text-nowrap text-link-blue" href={vppaLegalDoc.source} target="_blank">
                  Video Privacy Policy
                </InternalLink>
                .
              </Translate>
            </ParagraphSM>
          </div>
        ) : null}

        <If condition={Boolean(acceptError || (acceptResponse && !acceptResponse.ok))}>
          <Then>
            <Translate i18nKey="acceptLegalDocumentFailedErrorMessagev2" t={t}>
              Sorry, an unexpected error occurred! Please try again later.
            </Translate>
            <Button
              onClick={handleClose}
              className="w-full border-0 px-8 py-3.5 !ring-0 focus:!opacity-90 hover:!opacity-90 active:!opacity-90"
              variant="oxide-primary"
            >
              <LabelSM>
                <Translate i18nKey="close" t={t}>
                  Close
                </Translate>
              </LabelSM>
            </Button>
          </Then>
          <Else>
            <Button
              onClick={handleAgreed}
              disabled={acceptLoading}
              className="w-full border-0 px-8 py-3.5 !ring-0 focus:!opacity-90 hover:!opacity-90 active:!opacity-90"
              variant="oxide-primary"
            >
              <LabelSM>{agreeButtonLabel}</LabelSM>
            </Button>
          </Else>
        </If>
      </div>
    </Modal>
  )
}

interface LegalCookie {
  [key: string]: 'viewed'
}

function readLegalCookie(): LegalCookie | undefined {
  const legalCookieStr = getCookie(UPDATED_LEGAL_DOCUMENTS)
  if (typeof legalCookieStr !== 'string') return undefined

  try {
    const obj = JSON.parse(legalCookieStr)
    if (typeof obj === 'object') return obj
    throw new Error(`JSON.parse succeeded for the legal cookie, but the parsed result is not an object!`)
  } catch (err) {
    logger().warn(`Failed to parse the legal cookie string.`, { err, legalCookieStr })
    deleteCookie(UPDATED_LEGAL_DOCUMENTS)
    return undefined
  }
}

function writeLegalCookie(cookie: LegalCookie): void {
  setCookie(UPDATED_LEGAL_DOCUMENTS, JSON.stringify(cookie), { maxAge: Durations.ONE_DAY_IN_SECONDS })
}
