import { createContext, PropsWithChildren, ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { isCookieConsentDialogVisible } from '@/molecules/CookieManagement'
import { GetAppBanner } from '@/organisms/GetAppBanner'
import { AppBannerAnalyticsContext } from '@/organisms/GetAppBanner/types'
import { getBooleanFromSessionStorage, writeToSessionStorage } from '@/utils/session-storage'
import { UPDATED_LEGAL_DOCS_MODAL_ID } from '../UpdatedLegalDocsModal/UpdatedLegalDocsModal'

export interface GetAppBannerContext extends PropsWithChildren<unknown> {
  deepLink: string
  bannerVisible: boolean
  isLoading: boolean
}

export type GetAppBannerProviderProps = PropsWithChildren<{
  deepLink: string
  projectSlug?: string
  context: AppBannerAnalyticsContext
}>

export type GetAppBannerProvider = (props: GetAppBannerProviderProps) => ReactElement<GetAppBannerContext>

export const GetAppBannerContext = createContext<GetAppBannerContext>({
  deepLink: '',
  bannerVisible: false,
  isLoading: true,
})

export const GetAppBannerProvider = ({
  children,
  deepLink,
  projectSlug,
  context,
}: GetAppBannerProviderProps): ReactElement<GetAppBannerContext> => {
  const [bannerVisible, setBannerVisible] = useState(false)

  useEffect(() => {
    if (!isMobile) return

    const timeout = setTimeout(() => {
      if (typeof document === 'undefined') return

      const isCookieConsentVisible = isCookieConsentDialogVisible()
      const isShowingUpdatedLegalDocsModal = Boolean(document.getElementById(UPDATED_LEGAL_DOCS_MODAL_ID))
      const hasDismissed = getBooleanFromSessionStorage('getAppBannerDismissed', false)

      if (isCookieConsentVisible || isShowingUpdatedLegalDocsModal || hasDismissed) return

      setBannerVisible(true)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [])

  const value = useMemo(() => {
    return { bannerVisible, deepLink, isLoading: false }
  }, [bannerVisible, deepLink])

  return (
    <GetAppBannerContext.Provider value={value}>
      {children}
      <GetAppBanner
        deepLink={deepLink}
        showBanner={bannerVisible}
        onClose={() => {
          writeToSessionStorage('getAppBannerDismissed', true)
          setBannerVisible(false)
        }}
        analytics={{
          context,
          projectSlug,
        }}
      />
    </GetAppBannerContext.Provider>
  )
}

export const useGetAppBanner = (): GetAppBannerContext => {
  return useContext(GetAppBannerContext)
}
