import React from 'react'
import { LinkButton } from '@/atoms/Button'
import { AsH3, HeadingSM, ParagraphLG } from '@/atoms/Text'
import { paths } from '@/constants'
import { ProjectMetaContext } from '@/contexts/ProjectMetaContext/ProjectMetaContext'
import { useTranslate } from '@/utils/translate/translate-client'
import { AboutSectionContainer } from '@/views/WatchAboutView/AboutSectionContainer'

export const OverviewSection = () => {
  const { t } = useTranslate('watch')
  const { project, catalogTitle } = React.useContext(ProjectMetaContext)
  const description = catalogTitle?.description ? catalogTitle.description.long : project?.showOverview

  return (
    <AboutSectionContainer id="overview" className="flex flex-col">
      <div className="flex flex-col gap-10 lg:flex-row lg:gap-14">
        {description && (
          <div className="basis-1/2">
            <HeadingSM weight="bold" className="mb-4" as={AsH3}>
              {t('showOverview', 'Show Overview')}
            </HeadingSM>
            <ParagraphLG color="gray-300">{description}</ParagraphLG>
          </div>
        )}
        {project?.mission && (
          <div className="basis-1/2">
            <HeadingSM weight="bold" className="mb-4" as={AsH3}>
              {t('mission', 'Mission')}
            </HeadingSM>
            <ParagraphLG color="gray-300">{project.mission}</ParagraphLG>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        {project?.hasHubPage && (
          <LinkButton
            href={`${project?.projectType === 'movie' ? paths.movies.index : paths.shows.index}/${project?.slug}`}
            className="mt-10 w-fit"
            variant="white"
            outline
            target="_blank"
          >
            {t('learMore', 'Learn More')}
          </LinkButton>
        )}
      </div>
    </AboutSectionContainer>
  )
}
