import React from 'react'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { LightFranchise } from '@/services/FranchiseService'
import { Project } from '@/services/ProjectsService'
import { FranchiseLink } from '../WatchTheatricalView/FranchiseLink'
import { CrowdfundingSection } from './CrowdfundingSection'
import { FaqSection } from './FaqSection'
import { OverviewSection } from './OverviewSection'

interface WatchAboutViewProps {
  franchise?: LightFranchise
  projectData: Project
}

export const WatchAboutView: React.FC<WatchAboutViewProps> = ({ franchise, projectData }) => {
  return (
    <div className="flex flex-col">
      <PaddedContainer fluid>
        <OverviewSection />
        {franchise && projectData?.franchiseDetails && (
          <FranchiseLink projectName={projectData?.name} franchise={franchise} projectType={projectData?.projectType} />
        )}
        <CrowdfundingSection />
        <FaqSection />
      </PaddedContainer>
    </div>
  )
}
