import { memo, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { isMobile } from 'react-device-detect'
import { When } from 'react-if'
import { CircleAlertIcon } from '@/atoms/Icons/CircleAlertIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { useToast } from '@/molecules/Toast'
import {
  CatalogTitle,
  findHydraExternalId,
  formatTitleLikeLandscapeImagePath,
  formatTitleMoviePosterPath,
  hasHydraIdAndSlug,
} from '@/services/ContentCatalog'
import { createOneToManyShareLink } from '@/services/ContentShareService'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { getAccessTokenFromClient } from '@/utils/auth-utils'
import { logger } from '@/utils/logging'
import { getBasePath } from '@/utils/sitemap/base'
import { useTranslate } from '@/utils/translate/translate-client'
import { getUserDisplayName } from '@/utils/users'
import { OneToManyModal } from './OneToManyModal'
import { OneToManySheet } from './OneToManySheet'

interface OneToManyContentSharingModalProps {
  projectSlug: string
  projectTitle?: CatalogTitle
  watchableTitle?: CatalogTitle
  episodeId?: string
  showShareModal: boolean
  setShowShareModal: (showShareModal: boolean) => void
}

export const OneToManyContentSharingModal: React.FC<OneToManyContentSharingModalProps> = memo(
  ({ projectSlug, projectTitle, watchableTitle, episodeId, showShareModal, setShowShareModal }) => {
    const { t } = useTranslate('watch')
    const track = useSafeTrack()
    const toast = useToast()
    const searchParams = useSearchParams()
    const { locale } = useLocale()
    const { user } = useUser()

    const [shareUrl, setShareUrl] = useState('')
    const [linkError, setLinkError] = useState<string | null>(null)
    const [isCreateLoading, setIsCreateLoading] = useState(false)

    const displayName = getUserDisplayName(user)
    const moviePosterPath = getMoviePosterPath(projectTitle)
    const movieMobilePosterPath = getMobileMoviePosterPath(projectTitle)

    const shareLinkCopiedText = t('shareLinkCopied', 'Share Link Copied.')
    const shareLinkErrorText = t('shareLinkError', 'There was an error copying the link. Please try again.')
    const unknownErrorText = t('unknownShareError', 'An error occurred! Please try again later.')

    const baseUrl = getBasePath(locale)
    const canCreateShareLink =
      watchableTitle &&
      episodeId &&
      hasHydraIdAndSlug(watchableTitle) &&
      findHydraExternalId(watchableTitle) === episodeId

    const handleLinkCopied = useCallback(() => {
      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          toast?.showToast(shareLinkCopiedText, { icon: <CopyIcon size={24} color="gray-200" /> })
        })
        .catch(() => {
          toast?.showToast(shareLinkErrorText, { icon: <CircleAlertIcon size={24} color="gray-200" /> })
        })
    }, [shareLinkCopiedText, shareLinkErrorText, shareUrl, toast])

    const createAndCopyLink = useCallback(() => {
      if (!watchableTitle || !watchableTitle.id || !episodeId) return

      setIsCreateLoading(true)
      setLinkError(null)

      createOneToManyShareLink({
        accessToken: getAccessTokenFromClient(),
        watchableTitle,
        baseUrl,
        episodeId,
      })
        .then((generatedUrl) => {
          setIsCreateLoading(false)

          if (!generatedUrl) {
            setLinkError(unknownErrorText)
            return
          }

          track('Guild Content Share Link Created', {
            sharer_id: user?.id,
            slug: projectSlug,
            content_uuid: episodeId,
            titleId: watchableTitle.id,
            type: 'one-to-many',
          })

          setShareUrl(generatedUrl)
          navigator.clipboard
            .writeText(generatedUrl)
            .then(() => {
              toast?.showToast(shareLinkCopiedText, { icon: <CopyIcon size={24} color="gray-200" /> })
            })
            .catch((err) => {
              logger().warn('Failed to programmatically write the share link to the clipboard', { generatedUrl }, err)
            })
        })
        .catch((error) => {
          setIsCreateLoading(false)
          setLinkError(unknownErrorText)
          logger().error('Error creating share link', error)
        })
    }, [user?.id, watchableTitle, projectSlug, episodeId, toast, track, baseUrl, shareLinkCopiedText, unknownErrorText])

    const handleModalClose = () => {
      setShowShareModal(false)
    }

    useEffect(() => {
      searchParams.has('share') && setShowShareModal(true)
    }, [searchParams, setShowShareModal])

    return (
      <When condition={canCreateShareLink}>
        <When condition={isMobile}>
          <OneToManySheet
            showModal={showShareModal}
            handleModalClose={handleModalClose}
            createCopyLink={createAndCopyLink}
            moviePosterPath={movieMobilePosterPath}
            catalogTitle={projectTitle}
            displayName={displayName}
            shareUrl={shareUrl}
            handleLinkCopied={handleLinkCopied}
            isCreateLoading={isCreateLoading}
            linkError={linkError}
          />
        </When>
        <When condition={!isMobile}>
          <OneToManyModal
            showModal={showShareModal}
            handleModalClose={handleModalClose}
            createCopyLink={createAndCopyLink}
            moviePosterPath={moviePosterPath}
            catalogTitle={projectTitle}
            displayName={displayName}
            shareUrl={shareUrl}
            handleLinkCopied={handleLinkCopied}
            isCreateLoading={isCreateLoading}
            linkError={linkError}
          />
        </When>
      </When>
    )
  },
)

OneToManyContentSharingModal.displayName = 'React.memo(OneToManyContentSharingModal)'

const getMoviePosterPath = (catalogTitle?: CatalogTitle): string | null | undefined => {
  return catalogTitle ? formatTitleMoviePosterPath(catalogTitle) : null
}

const getMobileMoviePosterPath = (catalogTitle?: CatalogTitle): string | null | undefined => {
  return catalogTitle ? formatTitleLikeLandscapeImagePath(catalogTitle) : null
}
