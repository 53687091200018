import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import { When } from 'react-if'
import { Image } from '@/atoms/Image'
import { CaptionSM } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useExperimentContext } from '@/experimentation'
import { LivestreamThumbnail } from '@/molecules/LivestreamThumbnail'
import { MerchButton } from '@/molecules/MerchButton'
import { GuildEarlyAccessModal } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModal'
import { CatalogTitle } from '@/services/ContentCatalog'
import { ShopifyStoreConfig } from '@/services/EarlyAccessService'
import { Promotion } from '@/services/GuildPromotionService'
import { Episode, ProjectType } from '@/services/ProjectsService'
import { getCurrentLivestreamQuery } from '@/services/ProjectsService/queries'
import { GetTheatricalPreReleasePageDataResult } from '@/services/TheatricalPreReleaseService'
import { useUser } from '@/services/UserService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { getIsAngelEmployeeUser } from '@/utils/users'
import { GuildCTAButton } from '@/views/GuildSignupView/GuildCTAButton'
import { GuildContentLinkingBanner } from '../CollectiblesPlayer/GuildContentLinking'
import { GuildJoinTheAdventureBanner } from '../GuildJoinTheAdventureBanner'
import { HeroHeader } from './HeroHeader'
import { HeroProjectDescriptionNew } from './HeroProjectDescription'
import { ResponsiveBackgroundContainer } from './ResponsiveBackgroundContainer'
import { WatchHeroCTA } from './WatchHeroCTA'
import { WatchMerchCard } from './WatchMerchCard'
import { JOIN_THE_ADVENTURE_PROJECTS } from './constants'

export type BackgroundPosition =
  | 'bg-top'
  | 'bg-center'
  | 'bg-bottom'
  | 'bg-left'
  | 'bg-left-bottom'
  | 'bg-left-top'
  | 'bg-right'
  | 'bg-right-bottom'
  | 'bg-right-top'

export interface WatchProjectHeroNewProps {
  className?: string
  cloudinaryImgPath: string
  ctaClassName?: string
  ctaPath?: string
  ctaText?: string
  descriptionTitle?: string
  duration?: number
  episodeTitle?: string
  hasPayItForward: boolean
  id: number
  imageClassName?: string
  imageContainerClassName?: string
  position?: number
  slug: string
  showDescription?: string
  showTitle?: string
  theaterBackgroundPosition?: BackgroundPosition
  theatricalRegion?: TheatricalRegion[]
  theatricalRelease?: TheatricalReleaseObject
  isGuildMemberContent?: boolean
  handlePageLoad?: () => void
  openGuildEarlyAccessModal?: () => void
  currentEpisode: Episode | undefined
  promotion: Promotion | null
  catalogTitle?: CatalogTitle
  projectType: ProjectType
  pageData?: GetTheatricalPreReleasePageDataResult
  shopifyConfig?: ShopifyStoreConfig
  contentfulProjectTheme: Maybe<ProjectTheme>
  guildScore?: number
  isGuildMember: boolean
}

export const WatchProjectHeroNew: React.FC<WatchProjectHeroNewProps> = (props) => {
  const {
    className,
    cloudinaryImgPath,
    ctaClassName,
    ctaPath,
    ctaText,
    duration,
    id,
    position,
    showDescription,
    showTitle,
    slug,
    theaterBackgroundPosition,
    isGuildMemberContent,
    currentEpisode,
    promotion,
    projectType,
    pageData,
    shopifyConfig,
    contentfulProjectTheme,
    guildScore,
    isGuildMember,
  } = props
  const { t } = useTranslate('watch')
  const { track } = useSafeAnalytics()
  const { user } = useUser()
  const { data: currentLivestreamData, loading: loadingLivestream } = useQuery(getCurrentLivestreamQuery, {
    variables: { projectId: id },
    errorPolicy: 'all',
  })
  const { getFeatureValue } = useExperimentContext()
  const isAngelEmployeeUser = getIsAngelEmployeeUser(user?.email)
  const showJoinTheAdventureBanner =
    !isGuildMember &&
    JOIN_THE_ADVENTURE_PROJECTS.includes(slug) &&
    getFeatureValue('guild_join_the_adventure_banner', false)
  const projectShareAvailable = projectType === 'movie' && isGuildMemberContent && isGuildMember
  const showShareBanner = projectShareAvailable && (isAngelEmployeeUser || isGuildMember) && slug !== slugs.homestead

  const merchAnalytics = useMemo(() => {
    return { context: 'watch-project', program: slug }
  }, [slug])

  const showDefaultMerch = [slugs.tuttleTwins, slugs.wingfeatherSaga].includes(slug)
  const canShowStats = Boolean(
    !shopifyConfig && pageData?.filmDetails?.statsBox && !currentLivestreamData?.currentLivestream,
  )
  const canShowMerch = Boolean(shopifyConfig && !currentLivestreamData?.currentLivestream)

  const handleGuildClick = useCallback(() => {
    track('Guild Watch Join Clicked')
  }, [track])

  const handleShareClick = useCallback(() => {
    track('Content Link Project Share Film Clicked')
  }, [track])

  const handleLearnClick = useCallback(() => {
    track('Guild Watch Learn More Clicked')
  }, [track])

  const trackExperiment = useCallback(
    (projectSlug: string) => {
      track('Show Page CTA Clicked', { slug: projectSlug })
    },
    [track],
  )

  return (
    <>
      <div className={classNames(['flex flex-col gap-4 relative w-full lg:flex-row lg:flex-wrap', className])}>
        <ResponsiveBackgroundContainer
          cloudinaryImgPath={cloudinaryImgPath}
          theaterBackgroundPosition={theaterBackgroundPosition}
        />
        <div
          className={classNames('inset-0 fixed left-0 top-0 min-h-[70vh] w-screen transition-opacity')}
          style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.10) 50%)' }}
        />
        <div className="relative w-full md:mt-10 md:max-w-[575px] xl:max-w-[650px]">
          <HeroHeader
            loading={false}
            showTitle={showTitle}
            className="!photon-heading-sm md:!photon-heading-lg !text-left"
          />

          <HeroProjectDescriptionNew
            showTitle={showTitle}
            showDescription={showDescription}
            showEarlyAccess={isGuildMemberContent}
            slug={slug}
            handleLearnClicked={handleLearnClick}
            contentfulProjectTheme={contentfulProjectTheme}
            guildScore={guildScore}
          />
          <div>
            <div className="flex flex-col items-center gap-4 md:flex-row md:gap-0">
              {projectType === 'movie' && !isGuildMember && (
                <GuildCTAButton
                  className="!lg:px-6 !lg:py-[17px] !lg:max-h-[52px] !lg:rounded-[14px] !mb-0 !max-h-[40px] !rounded-[10px] !border-oxide-bright !bg-oxide-bright !px-5 py-[11px] !font-medium !leading-4"
                  guildButtonText={t('joinToWatch', 'Join To Watch')}
                  textLabelColor="core-gray-950"
                  iconColor="core-gray-950"
                  textClassName="!photon-label-sm !lg:photon-label-lg"
                  href={`/guild/join/${slug}`}
                  onClick={handleGuildClick}
                />
              )}
              <GuildEarlyAccessModal episode={currentEpisode} projectSlug={slug} promotion={promotion}>
                <WatchHeroCTA
                  ctaPath={ctaPath}
                  ctaText={ctaText}
                  iconColor={projectType === 'movie' ? 'white' : 'core-gray-950'}
                  ctaClassName={classNames(
                    '!max-h-[52px] !mb-0 !rounded-[10px] !lg:rounded-[14px] !photon-label-sm !lg:photon-label-lg !font-medium !lg:px-6 !lg:py-[17px] !px-5 !py-[11px] !leading-4',
                    {
                      '!bg-oxide-bright !border-oxide-bright': projectType === 'series',
                      '!border-core-gray-700/30 !bg-core-gray-700/30 !backdrop-blur-md !text-white md:ml-4':
                        projectType === 'movie',
                    },
                    ctaClassName,
                  )}
                  isGuildMemberContent={isGuildMemberContent}
                  position={position}
                  duration={duration}
                />
              </GuildEarlyAccessModal>
              {projectType === 'series' && !showJoinTheAdventureBanner && (
                <GuildCTAButton
                  className="!lg:px-6 !lg:py-[17px] !lg:max-h-[52px] !lg:rounded-[14px] !mb-0 !max-h-[40px] !rounded-[10px] !border-core-gray-700/30 !bg-core-gray-700/30 !px-5 py-[11px] !font-medium !leading-4 !backdrop-blur-md md:ml-4 lg:ml-0"
                  guildButtonText={t('joinGuild', 'Join the Guild')}
                  textClassName="!photon-label-sm !text-sm !lg:photon-label-lg"
                  href={`/guild/join/${slug}`}
                  onClick={handleGuildClick}
                />
              )}
              <When condition={currentLivestreamData?.currentLivestream && !showDefaultMerch}>
                <MerchButton
                  onClick={() => trackExperiment(slug)}
                  text={t('shopGifts', 'Shop Gifts')}
                  projectSlug={slug}
                  className={classNames(
                    '!max-h-[40px] !mb-0 !rounded-[10px] !lg:rounded-[14px] !photon-label-sm !lg:photon-label-lg !font-medium !lg:px-6 !lg:py-[17px] !px-5 !py-[11px] !leading-4 !border-core-gray-700/30 !bg-core-gray-700/30 !backdrop-blur-md !text-white',
                    ctaClassName,
                  )}
                />
              </When>
            </div>
          </div>
        </div>

        {projectType === 'series' && showJoinTheAdventureBanner && (
          <GuildJoinTheAdventureBanner
            className="lg:order-1"
            linkContext="watch-project-hero"
            onClick={handleGuildClick}
            slug={slug}
          />
        )}

        {showShareBanner && (
          <GuildContentLinkingBanner className="lg:order-1" onClick={handleShareClick} ctaPath={ctaPath} />
        )}
        <div
          className="relative mx-auto mt-4 flex items-center sm:mt-0 md:mx-0 md:ml-auto md:items-end"
          id="current-livestream"
        >
          <When condition={currentLivestreamData?.currentLivestream}>
            <When condition={!loadingLivestream}>
              <Transition
                show
                appear
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <LivestreamThumbnail
                  {...currentLivestreamData?.currentLivestream}
                  className="h-[180px] w-[320px]"
                  animationClassName="ease-in-out duration-[1000ms] md:hover:scale-[1.02]"
                />
              </Transition>
            </When>
          </When>
          <When condition={canShowStats}>
            <div className="flex min-h-[80px] w-[200px] flex-col items-center justify-start gap-2 rounded-2xl bg-core-gray-950/70 p-4 lg:min-h-[86px]">
              {pageData?.filmDetails?.statsBox?.logoImageUrl && (
                <div className="flex max-h-6 max-w-full items-center justify-center lg:max-h-[30px] lg:min-h-[30px]">
                  <Image
                    alt={pageData?.filmDetails?.statsBox?.statsDescription ?? ''}
                    height={24}
                    width={150}
                    className="mx-auto max-h-6 w-auto max-w-[150px] lg:max-h-[30px]"
                    src={getCloudinaryImageUrl({
                      path: pageData?.filmDetails?.statsBox?.logoImageUrl as string,
                      transforms: 'e_trim',
                    })}
                  />
                </div>
              )}
              <CaptionSM className="text-pretty" color="core-gray-300">
                {pageData?.filmDetails?.statsBox?.statsDescription}
              </CaptionSM>
            </div>
          </When>
          <When condition={canShowMerch}>
            {shopifyConfig && (
              <Transition
                show
                appear
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <WatchMerchCard
                  storeUrl={shopifyConfig.storeUrl}
                  accessToken={shopifyConfig.accessToken}
                  handle={shopifyConfig.handle}
                  storeHref={shopifyConfig?.storeHref}
                  analytics={merchAnalytics}
                  slug={slug}
                  isGuildMember={isGuildMember}
                />
              </Transition>
            )}
          </When>
        </div>
      </div>
    </>
  )
}
