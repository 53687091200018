import React, { TouchEvent as ReactTouchEvent, useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import { Button } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { ParagraphSM, TitleSM } from '@/atoms/Text'
import { CatalogTitle } from '@/services/ContentCatalog'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  showModal: boolean
  handleModalClose: () => void
  createCopyLink: () => void
  displayName: string
  moviePosterPath?: string | null
  catalogTitle?: CatalogTitle
  shareUrl: string
  handleLinkCopied?: () => void
  isCreateLoading?: boolean
  linkError: string | null
}

export const OneToManySheet: React.FC<Props> = ({
  showModal,
  handleModalClose,
  createCopyLink,
  displayName,
  moviePosterPath,
  catalogTitle,
  shareUrl,
  handleLinkCopied,
  isCreateLoading = false,
  linkError,
}) => {
  const { t } = useTranslate('watch')
  const ref = useRef<HTMLDivElement | null>(null)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const minSwipeDistance = 250
  const hasShareUrl = Boolean(shareUrl && shareUrl.length > 0)

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (showModal && ref.current && !ref.current.contains(event.target as Node)) {
        handleModalClose()
      }
    },
    [ref, handleModalClose, showModal],
  )

  const onTouchStart = (event: ReactTouchEvent) => {
    setTouchEnd(0)
    setTouchStart(event.targetTouches[0].clientY)
  }

  const onTouchMove = (event: ReactTouchEvent) => setTouchEnd(event.targetTouches[0].clientY)

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return
    const html = window.document.getElementsByTagName('html')[0]
    html.style.overflow = 'hidden'
    const distance = touchStart - touchEnd
    const isSwipeDown = distance < -minSwipeDistance
    if (showModal && isSwipeDown) {
      handleModalClose()
      html.style.overflow = 'auto'
    }
  }, [handleModalClose, showModal, touchStart, touchEnd])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <>
      {showModal && (
        <div
          className={classNames(
            'fixed inset-0 z-[1999] transition-all duration-300',
            showModal ? 'bg-black/75 bg-opacity-30' : 'bg-transparent bg-opacity-100',
          )}
        />
      )}
      <div
        ref={ref}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        className={classNames(
          'box-border fixed bottom-0 left-0 z-[2000] max-h-[90vh] se:max-h-[95vh] w-full rounded-t-3xl border-t bg-white transition-all duration-300 ease-in-out text-black',
          'sm:max-w-[400px] sm:right-0 sm:mx-auto sm:rounded-b-3xl sm:overflow-hidden sm:h-fit p-4 se:px-3 se:pb-3',
          showModal ? 'translate-y-0 sm:my-auto sm:bottom-0 sm:top-0 sm:opacity-1' : 'translate-y-full sm:opacity-0',
        )}
      >
        <div className="flex flex-col">
          <div className="">
            <div className="flex flex-col gap-4 se:gap-2">
              <div className="flex items-center justify-between">
                <TitleSM weight="bold">{t('shareGoodnessWithFriend', 'Share this goodness with a friend!')}</TitleSM>
                <div onClick={handleModalClose} className="cursor-pointer">
                  <CloseIcon color="black" size={24} className="cursor-pointer" />
                </div>
              </div>
              <ParagraphSM color="core-gray-700" className="se:!photon-caption-md">
                {t(
                  'sendALinkHelpAFriendManyLinks',
                  'As a Guild member, you can send a link and help a friend or loved one enjoy this title for free. As a Guild member you can share as many links as you would like. Share now!',
                )}
              </ParagraphSM>
              <div className="flex w-full flex-col gap-4 rounded-2xl bg-core-gray-100 p-4 se:gap-2 se:p-3">
                <div>
                  {moviePosterPath && (
                    <div className="block max-w-[198px] overflow-hidden rounded-lg">
                      <img
                        src={getCloudinaryImageUrl({ path: moviePosterPath, width: 198, height: 120 })}
                        className="min-h-[120px] min-w-[198px]"
                        alt={t('moviePosterAlt', '{{name}} Movie Poster', { name: catalogTitle?.title })}
                        height={120}
                        width={198}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <div className="flex flex-row gap-1">
                    <ParagraphSM weight="bold">{t('from', 'From')}:</ParagraphSM>
                    <ParagraphSM weight="medium">{displayName}</ParagraphSM>
                  </div>
                  <When condition={!hasShareUrl}>
                    <Button
                      variant="core-gray-900"
                      className="mt-4 min-w-[148px] px-7 py-[17px] se:mt-2"
                      onClick={createCopyLink}
                    >
                      <When condition={isCreateLoading}>
                        <LoadingSpinnerIcon />
                      </When>
                      <When condition={!isCreateLoading}>{t('createCopyLink', 'Create and Copy Link')}</When>
                    </Button>
                  </When>
                  <When condition={hasShareUrl}>
                    <div className="mt-4 flex max-h-[60px] flex-row">
                      <div className="flex max-w-[320px] items-center justify-start overflow-hidden text-nowrap rounded-l-lg border-[1.5px] border-core-gray-300 bg-white px-2 text-core-gray-950">
                        {shareUrl}
                      </div>
                      <Button
                        variant="core-gray-900"
                        className="m-0 min-w-fit rounded-l-none rounded-r-lg px-3 py-[17px]"
                        onClick={handleLinkCopied}
                      >
                        <CopyIcon color="white" size={32} className="mr-1.5" />
                        {t('copyLink', 'Copy Link')}
                      </Button>
                    </div>
                  </When>
                  <When condition={!!linkError}>
                    <ParagraphSM color="error-500">{linkError}</ParagraphSM>
                  </When>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
