import React from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { FAQPageJsonLd } from 'next-seo'
import { When } from 'react-if'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { FaqAccordion } from '@/organisms/FaqAccordion'
import { Faq } from '@/types/codegen-contentful'

export interface FaqSectionProps {
  faqs?: Faq[]
}
export const FaqSection: React.FC<FaqSectionProps> = (props) => {
  let { faqs } = useProjectMeta()
  if (!Array.isArray(faqs)) {
    faqs = props.faqs ?? []
  }

  return (
    <When condition={faqs && faqs.length > 0}>
      <FAQPageJsonLd
        mainEntity={faqs.map((faq) => ({
          questionName: faq.question,
          acceptedAnswerText: documentToPlainTextString(faq.answer?.json),
        }))}
      />
      {faqs && <FaqAccordion faqs={faqs} showTopBorder={true} />}
    </When>
  )
}
