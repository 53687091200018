import { memo, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { isMobile } from 'react-device-detect'
import { When } from 'react-if'
import { CircleAlertIcon } from '@/atoms/Icons/CircleAlertIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { useToast } from '@/molecules/Toast'
import { Asset } from '@/organisms/AngelPlayer'
import { CatalogTitle, formatTitleLikeLandscapeImagePath, formatTitleMoviePosterPath } from '@/services/ContentCatalog'
import { createShareLink, extractLastGuid } from '@/services/ContentShareService'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { logger } from '@/utils/logging'
import { getBasePath } from '@/utils/sitemap/base'
import { useTranslate } from '@/utils/translate/translate-client'
import { getUserDisplayName } from '@/utils/users'
import { GuildContentLinkingModal } from './GuildContentLinkingModal'
import { GuildContentLinkingSheet } from './GuildContentLinkingSheet'

interface OneToOneContentSharingModalProps {
  projectSlug: string
  catalogTitle?: CatalogTitle
  episodeId?: string
  asset?: Asset
  showShareModal: boolean
  setShowShareModal: (showShareModal: boolean) => void
}

export const OneToOneContentSharingModal: React.FC<OneToOneContentSharingModalProps> = memo(
  ({ projectSlug, catalogTitle, episodeId, asset, showShareModal, setShowShareModal }) => {
    const { t } = useTranslate('watch')
    const track = useSafeTrack()
    const toast = useToast()
    const searchParams = useSearchParams()
    const { locale } = useLocale()
    const { user } = useUser()

    const [text, setText] = useState('')
    const [recipient, setRecipient] = useState('')
    const [shareUrl, setShareUrl] = useState('')
    const [isCreateLoading, setIsCreateLoading] = useState(false)
    const [isCopied, setIsCopied] = useState(false)

    const displayName = getUserDisplayName(user)
    const moviePosterPath = getMoviePosterPath(catalogTitle)
    const movieMobilePosterPath = getMobileMoviePosterPath(catalogTitle)

    const shareLinkCopiedText = t('shareLinkCopied', 'Share Link Copied.')
    const shareLinkErrorText = t('shareLinkError', 'There was an error copying the link. Please try again.')

    const baseUrl = getBasePath(locale)

    const handleLinkCopied = useCallback(() => {
      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          toast?.showToast(shareLinkCopiedText, { icon: <CopyIcon size={24} color="gray-200" /> })
          setIsCopied(true)
        })
        .catch(() => {
          toast?.showToast(shareLinkErrorText, { icon: <CircleAlertIcon size={24} color="gray-200" /> })
        })
    }, [shareLinkCopiedText, shareLinkErrorText, shareUrl, toast])

    const createAndCopyLink = useCallback(() => {
      if (text?.length === 0 || recipient?.length === 0) {
        toast?.showToast(t('pleaseCompleteBothFields', 'Please complete both fields to share link'), {
          icon: <CircleAlertIcon size={24} color="gray-200" />,
        })
        return
      }
      setIsCreateLoading(true)
      createShareLink({
        variant: '1',
        sharer_id: user?.id,
        recipient_name: recipient,
        sender_note: text,
        slug: projectSlug,
        content_uuid: episodeId,
        m3u8_url: asset?.sourceConfig?.hls,
      })
        .then((response) => {
          const generatedUrl = response?.link
          track('Guild Content Share Link Created', {
            sharer_id: user?.id,
            slug: projectSlug,
            content_uuid: episodeId,
            titleId: catalogTitle?.id,
            type: 'one-to-one',
          })
          if (generatedUrl) {
            const lastGuid = extractLastGuid(generatedUrl)
            const shareUrl = `${baseUrl}/watch/share/${lastGuid}`
            setShareUrl(shareUrl)
            navigator.clipboard.writeText(shareUrl).then(() => {
              toast?.showToast(shareLinkCopiedText, { icon: <CopyIcon size={24} color="gray-200" /> })
            })
          }
          setIsCreateLoading(false)
        })
        .catch((error) => {
          setIsCreateLoading(false)
          logger().error('Error creating share link', error)
        })
    }, [
      text,
      recipient,
      user?.id,
      projectSlug,
      episodeId,
      asset?.sourceConfig?.hls,
      toast,
      t,
      track,
      baseUrl,
      shareLinkCopiedText,
      catalogTitle,
    ])

    const handleNewShare = () => {
      setRecipient('')
      setText('')
      setShareUrl('')
      setIsCopied(false)
    }

    const handleModalClose = () => {
      setShowShareModal(false)
    }

    useEffect(() => {
      searchParams.has('share') && setShowShareModal(true)
    }, [searchParams, setShowShareModal])

    return (
      <>
        <When condition={isMobile}>
          <GuildContentLinkingSheet
            showModal={showShareModal}
            handleModalClose={handleModalClose}
            createCopyLink={createAndCopyLink}
            moviePosterPath={movieMobilePosterPath}
            catalogTitle={catalogTitle}
            displayName={displayName}
            recipient={recipient}
            text={text}
            setRecipient={setRecipient}
            setText={setText}
            shareUrl={shareUrl}
            handleLinkCopied={handleLinkCopied}
            isCopied={isCopied}
            handleNewShare={handleNewShare}
            isCreateLoading={isCreateLoading}
          />
        </When>
        <When condition={!isMobile}>
          <GuildContentLinkingModal
            showModal={showShareModal}
            handleModalClose={handleModalClose}
            createCopyLink={createAndCopyLink}
            moviePosterPath={moviePosterPath}
            catalogTitle={catalogTitle}
            displayName={displayName}
            recipient={recipient}
            text={text}
            setRecipient={setRecipient}
            setText={setText}
            shareUrl={shareUrl}
            handleLinkCopied={handleLinkCopied}
            isCopied={isCopied}
            handleNewShare={handleNewShare}
            isCreateLoading={isCreateLoading}
          />
        </When>
      </>
    )
  },
)

OneToOneContentSharingModal.displayName = 'React.memo(OneToOneContentSharingModal)'

const getMoviePosterPath = (catalogTitle?: CatalogTitle): string | null | undefined => {
  return catalogTitle ? formatTitleMoviePosterPath(catalogTitle) : null
}

const getMobileMoviePosterPath = (catalogTitle?: CatalogTitle): string | null | undefined => {
  return catalogTitle ? formatTitleLikeLandscapeImagePath(catalogTitle) : null
}
